import LinkedIn from "../../assets/images/linkedIn.svg";

export const values = [
  {
    id: 1,
    title: "Customer-First",
    desc: "The needs of patients - and the healthcare providers who serve them - guide our strategy and policies. Our reason for existence is to make patients healthier",
    icon: "https://res.cloudinary.com/health-id/image/upload/v1664889284/Lifestores%20Healthcare%20Website/customer.svg",
  },
  {
    id: 2,
    title: "One Team",
    desc: "Given the importance and difficulty of our mission, we recognize that we need team members with different areas of expertise all working together collaboratively to achieve the common goal",
    icon: "https://res.cloudinary.com/health-id/image/upload/v1664889285/Lifestores%20Healthcare%20Website/oneteam.svg",
  },
  {
    id: 3,
    title: "Ownership",
    desc: "Our team members are outcome-oriented, using their full creativity and energy to proactively anticipate challenges and achieve their objectives; we're a team of entrepreneurs",
    icon: "https://res.cloudinary.com/health-id/image/upload/v1664889285/Lifestores%20Healthcare%20Website/ownership.svg",
  },
  {
    id: 4,
    title: "Continual Improvement",
    desc: "We follow the mantra that if you can improve 1% per day, you improve 37x in a year's time. We use data to guide our self-improvement, in service of helping patients reach the best health outcomes possible ",
    icon: "https://res.cloudinary.com/health-id/image/upload/v1664889285/Lifestores%20Healthcare%20Website/improvement.svg",
  },
];


export const WhatMUD = [
  {
    id: 1,
    title: "By Pharmacists, For Pharmacies",
    desc: "We know first-hand what it takes to run pharmacies in Africa",
    icon: "https://res.cloudinary.com/health-id/image/upload/v1664889141/Lifestores%20Healthcare%20Website/pharmacies.svg",
  },
  {
    id: 2,
    title: "Proprietary technology",
    desc: "Our PharmIQ technology offers end-to-end support procurement, sales, inventory, credit, and patient management",
    icon: "https://res.cloudinary.com/health-id/image/upload/v1664889286/Lifestores%20Healthcare%20Website/technology.svg",
  },
  {
    id: 3,
    title: "Open Platform",
    desc: "Our open and neutral architecture promotes price transparency and easily integrates third party service providers",
    icon: "https://res.cloudinary.com/health-id/image/upload/v1664889285/Lifestores%20Healthcare%20Website/platform.svg",
  },
  {
    id: 4,
    title: "Experienced Team",
    desc: "We are led by repeat entrepreneurs and a dynamic team of healthcare providers and software developers",
    icon: "https://res.cloudinary.com/health-id/image/upload/v1664889285/Lifestores%20Healthcare%20Website/team.svg",
  },
];



export const peoples = [
  {
    id: 2,
    name: "Andrew Garza ",
    title: "Co-founder & CEO",
    photo: "https://res.cloudinary.com/health-id/image/upload/v1700576655/health-id/image/upload/Andrew-Garza.svg",
    linkedin: LinkedIn,
    linkedInUrl: "https://www.linkedin.com/in/andrewgarza/"
  },
  {
    id: 1,
    name: "Bryan Mezue",
    title: "Co-founder & CPO",
    photo: "https://res.cloudinary.com/health-id/image/upload/v1664889501/Lifestores%20Healthcare%20Website/mezue.png",
    linkedin: LinkedIn,
    linkedInUrl: "https://www.linkedin.com/in/bryan-mezue/"
  },
  {
    id: 3,
    name: "Kenneth Ahaotu",
    title: "Regulatory Advisor for Lifestores & Co-Founder",
    photo: "https://res.cloudinary.com/health-id/image/upload/v1665041571/Lifestores%20Healthcare%20Website/ken.svg",
    linkedin: LinkedIn,
    linkedInUrl: "https://www.linkedin.com/in/kenneth-ahaotu/"
  },
  {
    id: 4,
    name: "Ajibola Abdulkadir",
    title: "Country Manager",
    photo: "https://res.cloudinary.com/health-id/image/upload/v1700576339/health-id/image/upload/ajibola.svg",
    linkedin: LinkedIn,
    linkedInUrl: "https://www.linkedin.com/in/ajibolaabdulkadir/"
  },
  {
    id: 5,
    name: "Oyindamola Tella",
    title: "VP Supply Chain and Operations",
    photo: "https://res.cloudinary.com/health-id/image/upload/v1700577353/health-id/image/upload/Oyin.svg",
    linkedin: LinkedIn,
    linkedInUrl: "https://www.linkedin.com/in/oyindamola-tella-5b61bb81/"
  },
  {
    id: 6,
    name: "Ayoola Adagunodo",
    title: "VP Engineering",
    photo: "https://res.cloudinary.com/health-id/image/upload/v1702390797/ayoola-pic.svg",
    linkedin: LinkedIn,
    linkedInUrl: "https://www.linkedin.com/in/ayoola-adagunodo-59613720/"
  },
  {
    id: 7,
    name: "Damilola Ogala",
    title: "VP Product and Strategy",
    photo: "https://res.cloudinary.com/health-id/image/upload/v1700577636/health-id/image/upload/dami.svg",
    linkedin: LinkedIn,
    linkedInUrl: "https://www.linkedin.com/in/damilola-ogala-n%C3%A9e-onabowale-14142375/"
  },
  {
    id: 8,
    name: "Ebuka Ejiofor",
    title: "National Sales Manager",
    photo: "https://res.cloudinary.com/health-id/image/upload/v1700577541/health-id/image/upload/Ebuka.svg",
    linkedin: LinkedIn,
    linkedInUrl: "https://www.linkedin.com/in/ejiofor-chukwuebuka-765944bb/"
  },
  {
    id: 9,
    name: "Bamidele Nse-Jacobs",
    title: "Retail Operations Manager",
    photo: "https://res.cloudinary.com/health-id/image/upload/v1700577725/health-id/image/upload/bami.svg",
    linkedin: LinkedIn,
    linkedInUrl: "https://www.linkedin.com/in/bamidele-nse-jacobs-4b181aa4/"
  },
  {
    id: 10,
    name: "Samuel Adeoye",
    title: "Financial Controller",
    photo: "https://res.cloudinary.com/health-id/image/upload/v1706533073/Samuel.svg",
    linkedin: LinkedIn,
    linkedInUrl: "https://www.linkedin.com/in/samuel-adeoye/"
  },
];
